import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import Layout from '../components/Layout'
import Img from "gatsby-image";
import { graphql } from 'gatsby'

import Hero from '../components/Hero'

export default class BlogPage extends React.Component {
  render() {
    const {data} = this.props
    const {edges: posts} = data.blogs

    return (<Layout>
      <div className="primary-contain">
        <Hero headerimg={data.headerimage.childImageSharp.fluid}/>
        <div className="container">
          <div className="secondary-contain">
            <section className="columns">
              <div className="column is-8 is-offset-2">
                <h1 className="has-text-weight-bold is-size-2 bot-1">Latest Articles</h1>

                {
                  posts.map(({node: post}) => (<div className="post-larger" key={post.id}>
                    <Link to={post.fields.slug}>
                      <Img className="post-img" fixed={post.frontmatter.image.childImageSharp.fixed}/>
                      <h3 className="title is-size-4 primary">{post.frontmatter.title}</h3>
                    </Link>
                    <small>{post.frontmatter.date}</small>
                    <p>
                      {post.excerpt}
                      <br/>
                    </p>
                    <hr/></div>))
                }
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>)
  }
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({edges: PropTypes.array})
  })
}

export const pageQuery = graphql `
  query BlogQuery {
    headerimage: file(relativePath: {eq: "hero2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    blogs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                fixed(width: 600, height: 170) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
